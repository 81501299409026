import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Layout from '../../../components/v2/Layout';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '15px',
    marginTop: '50px',
  },
  categoryName: {
    margin: 8,
    fontWeight: 'bold',
    textAlign: 'center',
    minHeight: 35,
    lineHeight: 1.3,
  },
  business: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  businessLogo: {
    width: '25px',
  }
}));

export default () => {
  const classes = useStyles();

  return (
    // loading in layout
    <Layout bg='bg3' title='เลือกประเภทการใช้งาน'>
      <Grid container spacing={2}>
        <Grid item xs={9} key='1'>
          <Typography variant='h4' component='h4' style={{ fontWeight: 600, color: 'white' }}>My EMORI</Typography>
          <Typography variant='h5' component='h5' style={{ fontWeight: 600, color: 'white' }}>Emotional Diary</Typography>
        </Grid>
        <Grid item xs={3} key='2' className={classes.business} onClick={() => navigate('/emori/relation')}>
          <img src={require(`../../../assets/images/business_emori.svg`)} className={classes.businessLogo} />
          <Typography variant='subtitle1' component='p' style={{ color: 'white', fontSize: '11px' }}>Business</Typography>
        </Grid>
      </Grid>
      <Card className={classes.card} onClick={() => navigate('/emori')}>
        <CardMedia
          component='img'
          image={require(`../../../assets/images/personal_emori.png`)}
          style={{ width: '30%', marginTop: '20px' }}
        />
        <Typography variant='subtitle1' component='p' color='textPrimary' className={classes.categoryName}>
          {'Personal'}
        </Typography>
      </Card>
    </Layout>
  );
};
